.header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 32px;
  cursor: pointer;
  padding: 24px;

  &__text {
    position: absolute;
    top: 6%;
    left: 5%
  }

  &__title {
    margin-top: 0px;
    margin-bottom: 8px
  }
  &__subTitle {
    margin-top: 0px;
    margin-bottom: -3px
  }

  &__img {
    width: 100%;
    height: 125px;
    margin-right: 16px;
    position: relative;
  }
}


@media only screen and (max-width: 800px) {
  .header {
    &__text {
      left: 7%
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 14px 16px;
  }
}

@media only screen and (max-width: 460px) {
  .header {

    &__img {
      opacity: 25%;
      }

    &__text {
      left: 10%
    }
  }
}