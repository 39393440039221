@import "./common//scssVariables.scss";

.blog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;

  &__innerContainer {
    width: 60%
  }

  &__list {
    list-style: none;
    padding-left: 0px;
  }

  &__listItem {
    padding: 16px;

    h4 {
      margin-bottom: 4px
    }
    p {
      margin-top: 0px
    }
    &-link {
      text-decoration: none;
      color: $sky-blue;
      &:hover {
        color: $prussian-blue
      }
    }
    &-image {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .blog {
    &__innerContainer {
      width: 100%
    }
    &__loggedIn {
      padding: 0 16px
    }
  }
}