@import "./common//scssVariables.scss";

.blogPost {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding-bottom: 32px;

  &__middle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__inner {
    width: 60%;
  }

  &__postCopy {
    margin: 4px 0
  }

  &__image {
    width: 100%
  }

  &__loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__linkToHome {
  text-decoration: none;
  color: $sky-blue;
    &:hover {
      color: $prussian-blue
    }
  }
}

@media only screen and (max-width: 600px) {
  .blogPost {
    &__inner {
      width: 100%;
    }
    &__middle {
      padding: 16px;
      display: block;
    }
  }
}